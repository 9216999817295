import React from 'react'
import {Grid, Container, Icon, Header, Image} from 'semantic-ui-react'
import {useEffect, useContext} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {UserContext} from '../context/UserContext'
import {Link} from 'react-router-dom'
import {isAuth} from '../helpers/auth'
import {linkToBuyCerts} from '../helpers/certDataHelper'

const logo = require('../assets/images/logo.png')

const NoAuthHomePage = () => {
  return (
    <Header as="h2" textAlign="center" style={{marginTop: '1rem'}}>
      <Link to="/signin">Sign in</Link> or{' '}
      <Link to="/signup">create an account</Link>.
    </Header>
  )
}

const discordServerURL = process.env.REACT_APP_DISCORD_SERVER_URL

const InternalSiteClickableIcon = ({to, icon, message}) => {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate(to)
  }
  return (
    <Grid.Column>
      <Header icon>
        <Icon
          style={{cursor: 'pointer'}}
          name={icon}
          onClick={handleClick}
        />
        <Link to={to}>{message}</Link>
      </Header>
    </Grid.Column>
  )
}

const ExternalSiteClickableIcon = ({link, icon, message}) => {
  const handleClick = () => {
    window.open({link})
  }
  return (
    <Grid.Column>
      <Header icon>
        <Icon style={{cursor: 'pointer'}} name={icon} onClick={handleClick} />
        <a href={link}>{message}</a>
      </Header>
    </Grid.Column>
  )
}

const AuthHomePage = () => {
  return (
    <Grid
      container
      doubling
      columns={2}
      style={{marginTop: '2rem'}}
      textAlign="center"
    >
      <InternalSiteClickableIcon
        to="/certificates"
        icon="folder"
        message={'My certificates'}
      />
      <InternalSiteClickableIcon to="/exams" message={'My exams'} icon="file" />
      <ExternalSiteClickableIcon
        icon={'btc'}
        link={linkToBuyCerts}
        message={'Purchase exams'}
      />
      <ExternalSiteClickableIcon
        icon={'comment'}
        link={discordServerURL}
        message={'Private community'}
      />
    </Grid>
  )
}

const HomePage = () => {
  const {currentUser} = useContext(UserContext)
  const location = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    if (location.pathname == '/certificates') {
      navigate('/certificates')
    } else if (location.pathname == '/exams') {
      navigate('/exams')
    } else if (location.pathname == '/contact') {
      navigate('/contact')
    } else if (location.pathname == '/instructions') {
      navigate('/instructions')
    }
  }, [])
  return (
    <div>
      <Container text>
        <Image
          centered
          alt="Teach Yourself Crypto logo"
          src={logo}
          size="huge"
          style={{margin: '2rem'}}
        />

        <Header
          as="h1"
          content="Welcome to Teach Yourself Crypto certificate system."
          textAlign="center"
          style={{marginTop: '2rem'}}
        />
        {isAuth(currentUser) ? <AuthHomePage /> : <NoAuthHomePage />}
      </Container>
    </div>
  )
}

export default HomePage
