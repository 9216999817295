import React from 'react'
import {Button, Form, Grid, Header, Message, Segment} from 'semantic-ui-react'

import {useState, useEffect, useContext} from 'react'
import {useNavigate} from 'react-router-dom'

import {Auth} from 'aws-amplify'
import {UserContext} from '../../context/UserContext'

const validPasswordPattern = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\\^$*.\\[\\]{}\\(\\)?\\-"!@#%&\\/,><\':;|_~`])\\S{8,99}$')

const PasswordInformation = () => {
  return (
    <Message
      warning
      header="Your password must contain, at least:"
      list={[
        '8 characters',
        'One number',
        'One uppercase letter',
        'One lowercase letter',
        'One special character: ^$*.[]{}()?-"!@#%&/,><’:;|_~`'
      ]}
    />
  )
}

const PasswordDontMatch = () => {
  return <Message error header="Passwords don't match" />
}

const NewPassword = ({code, email}) => {
  const navigate = useNavigate()
  const [password, setPassword] = useState(null)
  const [confirmPass, setConfirmPass] = useState(null)
  const [passwordsMatch, setPasswordsMatch] = useState(false)
  const [validPassword, setValidPassword] = useState(false)

  const {currentUser} = useContext(UserContext)

  useEffect(() => {
    const isValid = password === null || validPasswordPattern.test(password)
    if (isValid) {
      setValidPassword(true)
    } else {
      setValidPassword(false)
    }
    if (password === confirmPass) {
      setPasswordsMatch(true)
    } else {
      setPasswordsMatch(false)
    }
  })

  const handleSetNewPassword = async () => {
    if (!passwordsMatch) {
      return
    }
    try {
      const username = email
      const newPassword = password

      // Send confirmation code to user's email
      await Auth.forgotPasswordSubmit(username, code, newPassword)
      // To do
      navigate('/signin')
    } catch (e) {
      console.log('Error up', e)
    }
  }

  if (currentUser && currentUser.userConfirmed) {
    console.log('Current user', currentUser)
    console.log('User is already logged in and confirmed. Redirecting home.')
    navigate('/')
  }

  const onChangePassword = (e) => {
    e.persist()
    setPassword(e.target.value)
  }

  const onChangeConfirmPass = (e) => {
    e.persist()
    setConfirmPass(e.target.value)
  }

  return (
    <div className="login-form">
      <style>{`
      body > div,
      body > div > div,
      body > div > div > div.login-form {
        height: 100%;
      }
    `}</style>
      <Grid textAlign="center" style={{height: '75%'}} verticalAlign="middle">
        <Grid.Column style={{maxWidth: 450}}>
          <Header as="h2" color="yellow" textAlign="center">
                        Reset your password
          </Header>
          <Form size="large" onSubmit={handleSetNewPassword}>
            <Segment stacked>
              <Form.Input
                fluid
                icon="lock"
                iconPosition="left"
                placeholder="Password"
                required
                type="password"
                minLength="8"
                pattern={
                  '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\\^$*.\\[\\]{}\\(\\)?\\-"!@#%&\\/,><\':;|_~`])\\S{8,99}$'
                }
                onChange={onChangePassword}
              />
              <Form.Input
                fluid
                icon="lock"
                iconPosition="left"
                placeholder="Confirm password"
                type="password"
                required
                onChange={onChangeConfirmPass}
              />
              <Button color="yellow" fluid size="large">
                                Update
              </Button>
            </Segment>
          </Form>
          {validPassword ? null : <PasswordInformation />}
          {passwordsMatch ? null : <PasswordDontMatch />}
        </Grid.Column>
      </Grid>
    </div>
  )
}

export default NewPassword
