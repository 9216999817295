import React from 'react'
import '../../App.css'
import Certificate from './Certificate'
import {Container, Item} from 'semantic-ui-react'

const CertificateTable = ({passedCerts}) => {
  return (
    <div>
      <Container
        fluid
        text
        style={{
          marginBottom: '3rem',
          backgroundColor: '#f0f0f0',
          padding: '3rem',
          borderRadius: '5%'
        }}
      >
        <Item.Group divided relaxed>
          {passedCerts.map((cert, key) => {
            return (
              <Certificate
                key={key}
                name={cert.name}
                status={'Passed'}
                datePassed={cert.datePassed}
                link={cert.link}
              />
            )
          })}
        </Item.Group>
      </Container>
    </div>
  )
}

export default CertificateTable
