import React from 'react'
import {Form, Radio, Card} from 'semantic-ui-react'
import './examStyles.css'

const Question = (props) => {
  return (
    <Card fluid style={{marginBottom: '5erm', fontSize: '1.3rem'}}>
      <Card.Content style={{backgroundColor: '#f0f0f0'}}>
        <Card.Header>{props.question}</Card.Header>
      </Card.Content>
      <Card.Content>
        <Form>
          {props.options.map((option, idx) => {
            const key = `${idx}:${idx}`
            return (
              <Form.Field key={key}>
                <Radio
                  style={{fontSize: '1.3rem'}}
                  label={option}
                  name="radioGroup"
                  value={option}
                  checked={props.value === option}
                  onChange={(e, {value}) => props.handleClick(value, props.idx)}
                />
              </Form.Field>
            )
          })}
        </Form>
      </Card.Content>
    </Card>
  )
}

export default Question
