import React from 'react'
import {Message} from 'semantic-ui-react'

const WarningDiscord = () => {
  return (
    <Message
      warning
      header="Make sure your Discord ID is correct"
      list={[
        'The format is: Name#XXXX, where X is a number.',
        'Make sure this ID is the Discord ID of the user that joined the Discord server.'
      ]}
    />
  )
}
export default WarningDiscord
