import React from 'react'
import {Container, Image, Header} from 'semantic-ui-react'
const style = {
  marginTop: '2em'
}

const logo = require('../assets/images/logo.png')

const CannotTakeExamPage = ({message}) => {
  return (
    <Container text style={style}>
      <Image
        centered
        alt="Teach Yourself Crypto logo"
        src={logo}
        size="huge"
        style={{margin: '2rem'}}
      />
      <Header
        as="h1"
        content={message}
        style={{marginTop: '2rem'}}
        textAlign="center"
      />
    </Container>
  )
}

export default CannotTakeExamPage
