import React from 'react'
import {useState, useContext} from 'react'
import {Grid, Header, Segment, Form, Button} from 'semantic-ui-react'

import {Auth} from 'aws-amplify'
import {UserContext} from '../../context/UserContext'
// Renders with every new char

const ConfirmationCodeForm = ({setCode}) => {
  const [code, setInternalCode] = useState(null)
  const onChangeCode = (e) => {
    e.persist()
    setInternalCode(e.target.value)
  }
  const handleConfirmationCode = () => {
    setCode(code)
  }
  return (
    <Form size="large" onSubmit={handleConfirmationCode}>
      <Segment stacked>
        <Form.Input
          onChange={onChangeCode}
          fluid
          icon="user"
          iconPosition="left"
          placeholder="Confirmation code"
          required
        />
        <Button color="yellow" fluid size="large">
                    Send
        </Button>
      </Segment>
    </Form>
  )
}

const SendEmailForm = ({setShowCodeMenu, setEmail}) => {
  const [email, setInteralEmail] = useState(null)
  const onChangeEmail = (e) => {
    e.persist()
    setInteralEmail(e.target.value)
  }

  const sendCodeToEmail = async () => {
    try {
      // Send confirmation code to user's email
      await Auth.forgotPassword(email)
      setShowCodeMenu(true)
      setEmail(email)
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <Form size="large" onSubmit={sendCodeToEmail}>
      <Segment stacked>
        <Form.Input
          onChange={onChangeEmail}
          fluid
          icon="user"
          iconPosition="left"
          placeholder="Email address"
          type="email"
          required
        />
        <Button color="yellow" fluid size="large">
                    Send
        </Button>
      </Segment>
    </Form>
  )
}

const ForgotPassword = ({setCode, setEmail}) => {
  const [showCodeMenu, setShowCodeMenu] = useState(false)
  const {currentUser} = useContext(UserContext)

  if (currentUser && currentUser.userConfirmed) {
    console.log('User is already logged in. Redirecting home.')
    navigate('/')
  }

  return (
    <div className="login-form">
      <style>{`
      body > div,
      body > div > div,
      body > div > div > div.login-form {
        height: 100%;
      }
    `}</style>
      <Grid textAlign="center" style={{height: '75%'}} verticalAlign="middle">
        <Grid.Column style={{maxWidth: 450}}>
          <Header as="h2" color="yellow" textAlign="center">
                        Reset your password
          </Header>
          {showCodeMenu ? (
                        <ConfirmationCodeForm setCode={setCode} />
                    ) : (
                        <SendEmailForm setShowCodeMenu={setShowCodeMenu} setEmail={setEmail} />
                    )}
        </Grid.Column>
      </Grid>
    </div>
  )
}

export default ForgotPassword
