const isAuth = (user) => {
  // No user
  if (!user) {
    console.log('No user')
    return false
  }

  if (!user.attributes) {
    console.log('No attributes')
    return false
  }
  // User is not confirmed yet
  if (user.attributes.email_verified === false) {
    console.log('Email not verified')
    return false
  }
  return true
}

export {isAuth}
