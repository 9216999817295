const allCertificates = [
  'Bitcoin',
  'Ethereum and Smart Contracts',
  'Web3 (Tokens, DApps, Oracles, Filecoin and IPFS, The Graph)',
  'Decentralized Finance (DeFi)',
  'Privacy Coins'
]
const getCertId = (certName) => 1 + allCertificates.indexOf(certName)
const getCertName = (certId) => allCertificates[certId - 1]

const linkToBuyCerts = process.env.REACT_APP_BUY_CERTS_URL

const certsData = new Map([
  [
    'Bitcoin',
    {
      image: require('../assets/images/bitcoin.png'),
      id: 1,
      description: 'Bitcoin cert description',
      courseURL: '#module-1-bitcoin'
    }
  ],
  [
    'Privacy Coins',
    {
      image: require('../assets/images/monero.png'),
      id: 5,
      description: 'Privacy coins cert description',
      courseURL: '#module-5-privacy-coins'
    }
  ],
  [
    'Web3 (Tokens, DApps, Oracles, Filecoin and IPFS, The Graph)',
    {
      image: require('../assets/images/web3.png'),
      id: 3,
      description: 'Web3 cert description',
      courseURL:
        '#module-3-web3-tokens-dapps-oracles-filecoin-and-ipfs-the-graph'
    }
  ],
  [
    'Decentralized Finance (DeFi)',
    {
      image: require('../assets/images/defi.png'),
      id: 4,
      description: 'DeFi cert description',
      courseURL: '#module-4-decentralized-finance-defi'
    }
  ],
  [
    'Ethereum and Smart Contracts',
    {
      image: require('../assets/images/ethereum.png'),
      id: 2,
      description: 'Ethereum cert description',
      courseURL: '#module-2-ethereum-and-smart-contracts'
    }
  ]
])

const getCertImage = (name) => {
  return certsData.get(name)['image']
}
const getCertDescription = (name) => {
  return certsData.get(name)['description']
}
const getCertCourseURL = (name) => {
  return certsData.get(name)['courseURL']
}

const getDate = (timeInMs = Date.now()) => {
  const date = new Date(timeInMs)
  let [, month, day, year] = date.toDateString().split(' ')
  if (day.startsWith('0')) {
    day = day[1]
  }
  const finalDate = `${month} ${day} ${year}`
  return finalDate
}
const getLinkToShare = (url) => {
  const tokens = url.split('/')
  const hashID = tokens[tokens.length - 1]
  // Get current url
  const baseUrl =
    process.env.REACT_APP_USER_BRANCH === 'prod' ?
    'https://certificates.teachyourselfcrypto.com/certificates':
    'https://test.certificates.teachyourselfcrypto.com/certificates'
  return `${baseUrl}/${hashID}`
}

const isValidCertName = (certName) => {
  return allCertificates.includes(certName)
}
export {
  getCertId,
  getCertName,
  getCertImage,
  getCertDescription,
  getCertCourseURL,
  getDate,
  getLinkToShare,
  linkToBuyCerts,
  isValidCertName
}
