import React from 'react'
import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {Header, Card, Image, Message} from 'semantic-ui-react'
import {getDate, getCertCourseURL} from '../helpers/certDataHelper'
import {getAccomplishmentData} from '../helpers/dynamoDBHelper'
import Loading from '../components/messages/Loading'

const UserData = ({userName, courseName, completionDate}) => {
  return (
    <Card fluid>
      <Card.Content style={{padding: '1rem'}}>
        <Card.Header style={{fontSize: '2rem', paddingBottom: '1rem'}}>
          Completed by {userName}
        </Card.Header>
        <Card.Meta style={{fontSize: '1.3rem', color: 'black'}}>
          <span className="date">{getDate(completionDate)}</span>
        </Card.Meta>
        <Card.Description style={{fontSize: '1.6rem', lineHeight: '2.2rem'}}>
          {userName}&apos;{userName.endsWith('s') ? '' : 's'} account is
          verified.{' '}
          <a href="http://teachyourselfcrypto.com"> Teach Yourself Crypto </a>{' '}
          certifies their successful completion of the{' '}
          <a
            href={`http://teachyourselfcrypto.com/${getCertCourseURL(
              courseName
            )}`}
          >
            {courseName}
          </a>{' '}
          module.
        </Card.Description>
      </Card.Content>
    </Card>
  )
}

/*
const LinkToCourse = ({courseName, numUsersTakenCert = 1000}) => {
  return (
    <Card>
      <Card.Content>
        <Card.Header style={{fontSize: '2rem'}}>
          <a
            href={`http://teachyourselfcrypto.com/${getCertCourseURL(
              courseName
            )}`}
          >
            {courseName}
          </a>
        </Card.Header>
        <Card.Description style={{fontSize: '1.6rem', lineHeight: '2.2rem'}}>
          {getCertDescription(courseName)}
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Icon name="user" />
        {numUsersTakenCert} others have also passed this exam
      </Card.Content>
    </Card>
  )
}
  */

const InvalidCertificatePage = () => {
  return (
    <Message
      error
      size="massive"
      icon="inbox"
      header="This certificate is not valid"
      content="We could not find a valid certificate matchig this ID in our database."
    />
  )
}

const CertPageHeader = ({courseName}) => {
  return (
    <div style={{marginBottom: '2rem'}}>
      <Header as="h2" textAlign="center">
        <Header.Content>Course Certificate</Header.Content>
      </Header>
      <Header textAlign="center" style={{fontSize: '4.5rem'}}>
        <Header.Content>{courseName}</Header.Content>
      </Header>
    </div>
  )
}

const imagePathInBucket =
  process.env.REACT_APP_USER_BRANCH === 'prod' ?
  'https://certs-pub-prod.s3.amazonaws.com/public' :
    'https://certs-pub-dev.s3.amazonaws.com/public'

const AccomplishmentPage = () => {
  const {certHash} = useParams()
  const [userName, setUserName] = useState(null)
  const [courseName, setCourseName] = useState(null)
  const [completionDate, setDate] = useState(null)
  const [certLink, setCertLink] = useState(null)
  const [loading, setLoading] = useState(true)
  const [isValidCert, setIsValidCert] = useState(false)

  useEffect(async () => {
    if (!loading) return
    console.log('Getting data related to:', certHash)
    const {certName, date, name} = await getAccomplishmentData(certHash)
    if (!certName && !date && !name) {
      console.log('Invalid certificate')
      setIsValidCert(false)
    } else {
      setCertLink(`${imagePathInBucket}/${certHash}`)
      setUserName(name)
      setCourseName(certName)
      setDate(date)
      setIsValidCert(true)
    }

    setLoading(false)
  }, [])

  if (loading) {
    return <Loading text="Loading user's data" />
  } else if (isValidCert) {
    return (
      <div>
        <CertPageHeader courseName={courseName} />
        <UserData
          style={{marginTop: '2rem', marginBottom: '2rem'}}
          userName={userName}
          courseName={courseName}
          completionDate={completionDate}
        />
        <Image src={certLink} />
      </div>
    )
  } else if (!isValidCert) {
    return <InvalidCertificatePage />
  }
}

export default AccomplishmentPage
