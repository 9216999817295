import React from 'react'
import {Container, Header, Image} from 'semantic-ui-react'
import {linkToBuyCerts} from '../helpers/certDataHelper'

const logo = require('../assets/images/logo.png')

const NoExamsPage = () => {
  return (
    <Container text style={{marginTop: '5rem'}}>
      <Image
        centered
        alt="Teach Yourself Crypto logo"
        src={logo}
        size="huge"
        style={{margin: '2rem'}}
      />
      <Header as="h3" className="ui center aligned header">
        You do not have access to any exams at this moment.
      </Header>
      <h3 className="ui center aligned header">
        <a href={linkToBuyCerts}> Click here</a> to start purchasing them.
      </h3>
    </Container>
  )
}

export default NoExamsPage
