import React from 'react'
import {useContext} from 'react'
import {useSelector} from 'react-redux'
import {Link, Navigate} from 'react-router-dom'
import {UserContext} from '../context/UserContext'
import Loading from '../components/messages/Loading'
import NoExamsPage from './NoExamsPage'
import {Button, Container, Item} from 'semantic-ui-react'
import {
  getDate,
  getCertImage,
  getCertId
} from '../helpers/certDataHelper'
import {isAuth} from '../helpers/auth'

const currDate = Date.now()
const getCorrectLink = (status, retakenDate, name) => {
  let linkToResource = 'You cannot retake this exam yet'
  const certQuestionsId = getCertId(name)
  if (status === 'Failed' && retakenDate <= currDate) {
    linkToResource = `/exams/${certQuestionsId}`
  } else if (status === 'NotTaken') {
    linkToResource = `/exams/${certQuestionsId}`
  }
  return linkToResource
}

const FailedCert = ({retakenDate, linkToResource}) => {
  if (retakenDate <= currDate) {
    return (
      <div>
        <Button primary as={Link} to={linkToResource}>
          Take the exam
        </Button>
      </div>
    )
  } else {
    return (
      <div>
        <Item.Meta>Next attempt: {getDate(retakenDate)}</Item.Meta>
      </div>
    )
  }
}

const NotTakenCert = ({linkToResource}) => {
  return (
    <div>
      <Button primary as={Link} to={linkToResource}>
        Take the exam
      </Button>
    </div>
  )
}

const ExamItem = ({name, status, retakenDate}) => {
  const linkToResource = getCorrectLink(status, retakenDate, name)

  return (
    <Item>
      <Item.Image size="tiny" src={getCertImage(name)} />
      <Item.Content>
        <Item.Header style={{marginBottom: '1.5rem'}}>{name}</Item.Header>
        {status === 'Failed' ? (
          <FailedCert
            retakenDate={retakenDate}
            linkToResource={linkToResource}
          />
        ) : (
          <NotTakenCert linkToResource={linkToResource} />
        )}
      </Item.Content>
    </Item>
  )
}

const Exam = ({failedCerts, notTakenCerts}) => {
  return (
    <Container
      fluid
      text
      style={{
        backgroundColor: '#f0f0f0',
        padding: '2rem',
        borderRadius: '5%'
      }}
    >
      <Item.Group divided relaxed>
        {failedCerts.map((cert, key) => {
          return (
            <ExamItem
              key={key}
              name={cert.name}
              status={'Failed'}
              retakenDate={cert.retakenDate}
              link={cert.link}
            />
          )
        })}
        {notTakenCerts.map((cert, key) => {
          return (
            <ExamItem
              key={key}
              name={cert.name}
              status={'NotTaken'}
              link={cert.link}
            />
          )
        })}
      </Item.Group>
    </Container>
  )
}

const ExamPage = () => {
  const {currentUser} = useContext(UserContext)
  const loading = useSelector((state) => state.loading)
  const failedCerts = useSelector((state) => state.failed)
  const notTakenCerts = useSelector((state) => state.notTaken)

  if (!isAuth(currentUser)) {
    console.log('CertPage. User not signed in. Redirecting')
    console.log(currentUser)
    return <Navigate to="/signin" />
  }

  return (
    <div>
      <Container data-testid="certs-container">
        {loading ? (
          <Loading
            header={'Just one second'}
            text={"We're fetching your certificates"}
          />
        ) : failedCerts.length > 0 || notTakenCerts.length > 0 ? (
          <Exam failedCerts={failedCerts} notTakenCerts={notTakenCerts} />
        ) : (
          <NoExamsPage />
        )}
      </Container>
    </div>
  )
}

export default ExamPage
