import React from 'react'
import {useState, useContext} from 'react'

import {UserContext} from '../context/UserContext'
import ForgotPassword from '../components/auth/ForgotPassword'
import NewPassword from '../components/auth/NewPassword'

const ForgotPasswordPage = () => {
  const {currentUser} = useContext(UserContext)
  const [email, setEmail] = useState(null)
  const [code, setCode] = useState(null)

  if (currentUser && currentUser.userConfirmed) {
    console.log('User is already logged in. Redirecting home.')
    navigate('/')
  }

  if (code) return <NewPassword code={code} email={email}/>
  else return <ForgotPassword setCode={setCode} setEmail={setEmail}/>
}

export default ForgotPasswordPage
