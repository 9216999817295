const notTakenReducer = (state = [], action) => {
  switch (action.type) {
    case 'clean':
      return []
    case 'addNotTaken':
      return [...state, action.payload]
    case 'initNotTaken':
      return [...action.payload]
    case 'deleteNotTaken':
      return state.filter((s) => s.name !== action.payload.name)
    default:
      return state
  }
}
export default notTakenReducer
