import React from 'react'
import {Icon, Button} from 'semantic-ui-react'

const organizationId = 81329020
const LinkedInAddToProfile = ({
  certName,
  issueYear,
  issueMonth,
  certId,
  certURL
}) => {
  const expirationYear = ''
  const expirationMonth = ''

  const URL = `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${certName}&organizationId=${organizationId}&issueYear=${issueYear}&
issueMonth=${issueMonth}&expirationYear=${expirationYear}&expirationMonth=${expirationMonth}}&certUrl=${certURL}&certId=${certId}`

  return (
    <Button primary icon onClick={() => window.open(URL, '_blank')}>
      <Icon name="linkedin" />
    </Button>
  )
}

export default LinkedInAddToProfile
