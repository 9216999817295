import {combineReducers} from 'redux'
import passedReducer from './passedReducer'
import failedReducer from './failedReducer'
import notTakenReducer from './notTakenReducer'
import loadingReducer from './loadingReducer'

const reducers = combineReducers( {
  passed: passedReducer,
  failed: failedReducer,
  notTaken: notTakenReducer,
  loading: loadingReducer
})

export default reducers
