/* eslint-disable operator-linebreak */
/* eslint-disable comma-dangle */
import React, {useState, useEffect} from 'react'
import swal from 'sweetalert'

import {Button, Icon, Item, Message} from 'semantic-ui-react'
import {Storage} from 'aws-amplify'
import {
  getDate,
  getLinkToShare,
  getCertImage
} from '../../helpers/certDataHelper'
import LinkedInAddToProfile from './LinkedInAddToProfile'

const filenames = new Map([
  ['Bitcoin', 'Bitcoin'],
  ['Ethereum and Smart Contracts', 'Ethereum'],
  ['Ethereum and Smart Contracts', 'Ethereum'],
  ['Web3 (Tokens, DApps, Oracles, Filecoin and IPFS, The Graph)', 'Web3'],
  ['Decentralized Finance (DeFi)', 'DeFi'],
  ['Privacy Coins', 'PrivacyCoins']
])

const copyToClipboard = (str) => {
  const el = document.createElement('textarea')
  el.value = str
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)
  const selected =
    document.getSelection().rangeCount > 0
      ? document.getSelection().getRangeAt(0)
      : false
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
  if (selected) {
    document.getSelection().removeAllRanges()
    document.getSelection().addRange(selected)
  }
}

const monthNumberFromString = (str) => {
  return new Date(`${str} 01 2000`).toLocaleDateString('en', {month: '2-digit'})
}

const PassedCert = ({datePassed, linkToResource, name}) => {
  const [signedURL, setSignedURL] = useState(null)
  const [isBeingGenerated] = useState(linkToResource.includes('generat'))
  const {objectKey, certId, bucket, filename} = parseUrlLink(linkToResource, name)

  useEffect(async () => {
    const signedURL = await Storage.get(`${objectKey}`, {
      bucket,
      contentDisposition: `attachment; filename = ${filename}`
    })

    setSignedURL(signedURL)
  })

  return (
    <div>
      <Item.Meta style={{padding: '1rem 0 1rem 0'}}>
        Passed: {getDate(datePassed)}
      </Item.Meta>
      <Item.Content>
        {isBeingGenerated ? (
          <Message success>{linkToResource}</Message>
        ) : (
          <div>
            <Button
              icon
              primary
              onClick={() => window.location.replace(signedURL)}
            >
              <Icon name="download" />
            </Button>
            <Button
              icon
              primary
              onClick={() => {
                copyToClipboard(`${getLinkToShare(linkToResource)}`)
                swal(
                  'You just copied a link to this certificate',
                  'Share it with others so that they can see your accomplishment',
                  'success'
                )
              }}
            >
              <Icon name="copy" />
            </Button>
            <LinkedInAddToProfile
              certName={name}
              issueYear={getDate(datePassed).split(' ')[2]}
              issueMonth={monthNumberFromString(
                getDate(datePassed).split(' ')[0]
              )}
              certId={certId}
              certURL={`${getLinkToShare(linkToResource)}`}
            />
          </div>
        )}
      </Item.Content>
    </div>
  )
}

const Certificate = ({
  name,
  status,
  datePassed,
  link,
  numUsersTakenCert = 1000
}) => {
  return (
    <Item>
      <Item.Image size="tiny" src={getCertImage(name)} />
      <Item.Content>
        <Item.Header>{name}</Item.Header>
        {status === 'Passed' ? (
          <PassedCert
            name={name}
            datePassed={datePassed}
            linkToResource={link}
          />
        ) : null}
      </Item.Content>
    </Item>
  )
}

export default Certificate

const parseUrlLink = (linkToResource, name) => {
  const urlArray = linkToResource.split('/')
  const environment = process.env.REACT_APP_USER_BRANCH === 'prod' ? 'prod' : 'dev'
  const bucket = `certs-pub-${environment}`
  // It introduces automaticalle the prefix "public"
  const objectKey = `pdfs/${urlArray[urlArray.length - 1]}`
  // Discard the prefix pdfs/
  const certId = objectKey.substring(5)
  const filename = `${filenames.get(name)}.pdf`
  return {objectKey, certId, bucket, filename}
}
