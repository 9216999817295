const failedReducer = (state = true, action) => {
  switch (action.type) {
    case 'stop':
      return false
    case 'start':
      return true
    default:
      return state
  }
}
export default failedReducer
