import React from 'react'
import {useContext, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'

import {useDispatch} from 'react-redux'
import {bindActionCreators} from 'redux'
import {actionCreators} from '../state/index'

import {Auth} from 'aws-amplify'
import {UserContext} from '../context/UserContext'
import {isAuth} from '../helpers/auth'

const SignOut = () => {
  const navigate = useNavigate()
  const {currentUser, setCurrentUser} = useContext(UserContext)
  const dispatch = useDispatch()
  const AC = bindActionCreators(actionCreators, dispatch)

  const signOut = async () => {
    console.log('Signing out')
    if (!isAuth(currentUser)) {
      console.log('User is not logged in. Redirecting home.')
      navigate('/')
    } else {
      try {
        await Auth.signOut()

        AC.clean()
        AC.startLoading()

        setCurrentUser(null)
        console.log('Successfully signed out. Redirecting to Home')
        navigate('/', {replace: true})
      } catch (err) {
        console.log('Error signing out', err)
      }
    }
  }

  useEffect(() => {
    signOut()
  })

  return <div></div>
}

export default SignOut
