import {Routes, Route, Link} from 'react-router-dom'
import React, {useEffect, useState} from 'react'

import NavBar from './components/Navigation2'
import AccomplishmentPage from './pages/AccomplishmentPage'
import PassedCertificatesPage from './pages/PassedCertificatesPage'
import ErrorPage from './pages/ErrorPage'
import ExamsToTakePage from './pages/ExamsToTakePage'
import ExamPage from './pages/ExamPage'
import Contact from './pages/ContactPage'
import SignInPage from './pages/SignInPage'
import SignUpPage from './pages/SignUpPage'
import SignOutPage from './pages/SignOutPage'
import ProfilePage from './pages/ProfilePage'
import HomePage from './pages/HomePage'
import {isAuth} from './helpers/auth'
import ForgotPasswordPage from './pages/ForgotPasswordPage'
import InstructionsPage from './pages/InstructionsPage'

import {UserContext} from './context/UserContext'

import {useDispatch} from 'react-redux'
import {bindActionCreators} from 'redux'
import {actionCreators} from './state/index'
import {getCertificates} from './helpers/dynamoDBHelper'

import {Amplify, Auth} from 'aws-amplify'
import awsconfig from './aws-exports'

Amplify.configure(awsconfig)

const leftItems = [
  {as: Link, to: '/', content: 'Home', key: 'home'},
  {as: Link, to: '/contact', content: 'Contact Us', key: 'contact'},
  {as: Link, to: '/instructions', content: 'Instructions', key: 'instructions'}
]
const rightItemsAuth = [
  {as: Link, to: '/certificates', content: 'My Certificates', key: 'certs'},
  {as: Link, to: '/exams', content: 'My Exams', key: 'exams'},
  {as: Link, to: '/profile', content: 'My profile', key: 'profile'},
  {as: Link, to: '/signout', content: 'Sign Out', key: 'signout'}
]

const rightItemsNoAuth = [
  {as: Link, to: '/signin', content: 'Sign In', key: 'siginin'},
  {as: Link, to: '/signup', content: 'Sign Up', key: 'signup'}
]

const App = () => {
  const [currentUser, setCurrentUser] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const dispatch = useDispatch()
  const AC = bindActionCreators(actionCreators, dispatch)

  const fetchAvailableCerts = async (user) => {
    if (!isLoading) return
    console.log('Fetching certs')
    if (!user) return
    try {
      const {passed, failed, notTaken} = await getCertificates(user)

      AC.initializeCerts({passed, failed, notTaken})
      AC.stopLoading()
      console.log('All certificates have been fetched')
    } catch (err) {
      console.log('Error fetching certs', err)
    }
  }

  const getDataInitialContext = async () => {
    if (!isLoading) return
    try {
      console.log('Getting initial user')
      await Auth.currentSession()
      const user = await Auth.currentAuthenticatedUser()
      return user
    } catch (err) {
      console.log('Error fetching user from local storage:', err)
      return null
    }
  }

  useEffect(async () => {
    if (!isLoading) return
    if (currentUser) return

    const initialUser = await getDataInitialContext()
    await fetchAvailableCerts(initialUser)
    setCurrentUser(initialUser)
    setIsLoading(false)
  }, [currentUser])

  return (
    !isLoading && (
      <div>
        <UserContext.Provider value={{currentUser, setCurrentUser}}>
          <NavBar
            leftItems={leftItems}
            rightItemsNoAuth={rightItemsNoAuth}
            rightItemsAuth={rightItemsAuth}
            isAuthorized={isAuth(currentUser)}
          >
            <Routes>
              <Route path="/signin" element={<SignInPage />} />
              <Route path="/signup" element={<SignUpPage />} />
              <Route path="/signout" element={<SignOutPage />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/instructions" element={<InstructionsPage />} />
              <Route
                path="/certificates/:certHash"
                element={<AccomplishmentPage />}
              />
              <Route path="/" element={<HomePage />} />
              <Route
                path="/certificates"
                element={<PassedCertificatesPage />}
              />
              <Route path="/exams" element={<ExamsToTakePage />} />
              <Route path="/exams/:certId" element={<ExamPage />} />
              <Route path="*" element={<ErrorPage />} />
            </Routes>
          </NavBar>
        </UserContext.Provider>
      </div>
    )
  )
}

export default App
