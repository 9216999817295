import React from 'react'
const Loading = (props) => {
  return (
    <div className='ui icon message'>
      <i className='notched circle loading icon'></i>
      <div className='content'>
        <div className='header'>{props.header}</div>
        <p>{props.text}</p>
      </div>
    </div>
  )
}

export default Loading
