import React from 'react'
const FailedMessage = () => {
  return (
    <div className="ui negative message">
      <div className="header">Unfortunately, you did not pass the exam</div>
      <p>
        Take some time to review the content of this module and try again in 24h.
      </p>
    </div>
  )
}

export default FailedMessage
