import {Storage} from 'aws-amplify'

// Stores the answers to the last requested exam
let realAnswers = []

// Let client handle possible exceptions
const getQuestions = async (certId, company = 'tyc') => {
  const key = `${company}/cert-questions-${certId}.json`
  const response = await Storage.get(key, {download: true})
  const text = await response.Body.text()

  const dataObject = await JSON.parse(text)
  const data = dataObject.questions || []

  const questions = data.map((d) => {
    return {id: d.id, question: d.question, options: d.options}
  })

  realAnswers = data.map((d) => d.answer)

  return questions
}

const passingThreshold = 75
const processAnswers = (answers) => {
  if (answers.length !== realAnswers.length) return false
  let numCorrectAnswers = 0

  for (let i = 0; i < answers.length; ++i) {
    if (answers[i] === realAnswers[i]) {
      numCorrectAnswers++
    }
  }

  return (numCorrectAnswers / answers.length) * 100 >= passingThreshold
}

export {getQuestions, processAnswers}
