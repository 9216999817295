import React from 'react'
import {Link} from 'react-router-dom'
import {Container, Header, Image} from 'semantic-ui-react'

const logo = require('../assets/images/logo.png')

const NoCertsPage = () => {
  return (
    <Container text style={{marginTop: '5rem'}}>
      <Image
        centered
        alt="Teach Yourself Crypto logo"
        src={logo}
        size="huge"
        style={{margin: '2rem'}}
      />
      <Header as="h3" className="ui center aligned header">
        You have not earned any certificates yet. Pass{' '}
        <Link to="/exams">your exams</Link> to earn certificates.
      </Header>
    </Container>
  )
}

export default NoCertsPage
