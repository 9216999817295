import React from 'react'
import {Image, Container} from 'semantic-ui-react'
import {Link} from 'react-router-dom'
import {linkToBuyCerts} from '../helpers/certDataHelper'
const logo = require('../assets/images/logo.png')

const InstructionsPage = () => {
  return (
    <Container text style={{fontSize: '1.4rem'}}>
      <Image
        centered
        alt="Teach Yourself Crypto logo"
        src={logo}
        size="huge"
        style={{margin: '2rem'}}
      />
      <h1>Instructions</h1>
      <p>
        Thank you for logging into the Teach Yourself Crypto Certificate System.
      </p>
      <p>Here, you will be able to see your exams and certificates.</p>
      <ol>
        <li>
          <p>
            If you already purchased access to the exams, you&apos;ll find them
            in the My Exams section.
          </p>
        </li>
        <li>
          <p>
            If you haven&apos;t purchased the exams yet, please do so{' '}
            <a href={linkToBuyCerts}>here</a>. You may need to wait a minute for
            the exams you purchased to show up in the My Exams section.
          </p>
        </li>
      </ol>
      <p>
        If you do not see the exams you purchased in the My Exams section,
        please double check that you are logged in from the same email ID that
        you used to purchase the certificates. If you are still facing issues,
        please contact support.
      </p>
      <h2>Exam Instructions</h2>
      <ol>
        <li>
          <p>
            The exams are a set of 25 multiple choice questions, with 1 correct
            answer each.
          </p>
        </li>
        <li>
          <p>All questions carry equal weight.</p>
        </li>
        <li>
          <p>The minimum passing grade for each exam is 75%.</p>
        </li>
        <li>
          <p>
            You are allowed to refer to all the curriculum material while
            answering the exam, but you are not allowed to use any outside
            material.
          </p>
        </li>
        <li>
          <p>The exam should take no more than 25 minutes to complete.</p>
        </li>
        <li>
          <p>
            You are allowed as many attempts as needed until you pass the exam.
            However, you will need to wait 24 hours between two attempts. This
            is to give you time to prepare.
          </p>
        </li>
        <li>
          <p>
            In case you face any problems with the exam system, please{' '}
            <Link to="/contact"> contact </Link> support.
          </p>
        </li>
      </ol>
      <p>
        Once the exam is completed successfully (75% grade or above), a
        certificate will be generated. This may take a few minutes.
      </p>
      <p>
        Once the certificate is generated, it will be available in the My
        Certificates section. If you do not see it there after a couple of
        minutes, refresh the page. You will then be able to download it and
        share it on LinkedIn using our one click share button.
      </p>
    </Container>
  )
}

export default InstructionsPage
