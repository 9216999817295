const passedReducer = (state = [], action) => {
  switch (action.type) {
    case 'initPassed':
      return [...action.payload]
    case 'addPassed':
      return [...state, action.payload]
    case 'clean':
      return []
    default:
      return state
  }
}
export default passedReducer
