import React from 'react'
const PassedMessage = () => {
  return (
    <div className='ui success message'>
      <div className='header'>Congratulations, you passed the exam!</div>
      <p>
        Your certification is being generated and will be available shortly in
        the <i>My Certificates</i> section.
      </p>
    </div>
  )
}

export default PassedMessage
