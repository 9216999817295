export const initializeCerts = ({passed, failed, notTaken}) => {
  return (dispatch) => {
    dispatch({
      type: 'initPassed',
      payload: passed
    })
    dispatch({
      type: 'initFailed',
      payload: failed
    })
    dispatch({
      type: 'initNotTaken',
      payload: notTaken
    })
  }
}

export const clean = () => {
  return (dispatch) => {
    dispatch({
      type: 'clean'
    })
  }
}
export const passNotTakenCert = (certName) => {
  return (dispatch) => {
    dispatch({
      type: 'addPassed',
      payload: {
        name: certName,
        link: 'Your certificate is being generated now'
      }
    })
    dispatch({
      type: 'deleteNotTaken',
      payload: {
        name: certName
      }
    })
  }
}

export const passFailedCert = (certName) => {
  return (dispatch) => {
    dispatch({
      type: 'addPassed',
      payload: {
        name: certName,
        link: 'Your certificate is being generated now'
      }
    })
    dispatch({
      type: 'deleteFailed',
      payload: {
        name: certName
      }
    })
  }
}

export const failCert = (certName) => {
  return (dispatch) => {
    dispatch({
      type: 'addFailed',
      payload: {
        name: certName,
        retakenDate: Date.now() + 24 * 3600 * 1000
      }
    })
    dispatch({
      type: 'deleteNotTaken',
      payload: {
        name: certName
      }
    })
  }
}
export const failFailedCert = (certName) => {
  return (dispatch) => {
    dispatch({
      type: 'updateFailed',
      payload: {
        name: certName,
        retakenDate: Date.now() + 24 * 3600 * 1000
      }
    })
  }
}

export const stopLoading = () => {
  return (dispatch) => {
    dispatch({
      type: 'stop',
      payload: {}
    })
  }
}

export const startLoading = () => {
  return (dispatch) => {
    dispatch({
      type: 'start',
      payload: {}
    })
  }
}
