import React from 'react'
import {useState, useContext, useMemo} from 'react'
import {Navigate} from 'react-router-dom'

import {UserContext} from '../context/UserContext'
import SignUp from '../components/auth/SignUp'
import ConfirmSignUp from '../components/auth/ConfirmSignUp'
import {isAuth} from '../helpers/auth'

const SignUpPage = () => {
  const [email, setEmail] = useState(null)
  const [ethAddress, setEthAddress] = useState(null)
  const [password, setPassword] = useState(null)
  const [submitted, setSubmitted] = useState(false)

  const {currentUser} = useContext(UserContext)

  const isAlreadyAuth = useMemo(() => isAuth(currentUser), [currentUser])
  if (isAlreadyAuth) {
    console.log('User is already logged in and confirmed. Redirecting home.')
    return <Navigate to="/" />
  }
  return submitted ? (
    <ConfirmSignUp
      email={email}
      ethAddress={ethAddress}
      password={password}
    />
  ) : (
    <SignUp
      setSignUpUsername={setEmail}
      setSubmitted={setSubmitted}
      setEthAddress={setEthAddress}
      setPassword={setPassword}
    />
  )
}
export default SignUpPage
