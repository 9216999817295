import React from 'react'
import {Image} from 'semantic-ui-react'
const errorImage = require('../assets/images/404.jpg')
const ErrorPage = () => {
  return (
    <div>
      <Image
        src={errorImage}
        alt="Page Not Found"
        centered
      />
    </div>
  )
}

export default ErrorPage
