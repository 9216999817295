/* eslint-disable camelcase */
/* eslint-disable quote-props */
import {API, Auth} from 'aws-amplify'
import {getDate} from './certDataHelper'
import {isValidCertName} from './certDataHelper'

const getCertificates = async (currentUser) => {
  console.log('Getting certs for user:', currentUser.attributes.email)
  const token = currentUser.signInUserSession.idToken.jwtToken
  const requestData = {
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    }
  }

  try {
    const data = await API.get('userapi', '/users', requestData)
    // Que pasa si no estan definidos
    const passed = data.passed || []
    const failed = data.failed || []
    const notTaken = data.not_taken || []

    return {passed, failed, notTaken}
  } catch (err) {
    console.log('There was an error', err)
    return {}
  }
}

const getProfileData = async (currentUser) => {
  console.log('Getting certs for user:', currentUser.attributes.email)
  const token = currentUser.signInUserSession.idToken.jwtToken
  const requestData = {
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    }
  }

  try {
    const data = await API.get('userapi', '/users', requestData)
    console.log('Data:', data)
    const {ethAddress, discordId} = data
    console.log('Profile data:', ethAddress, discordId)

    return {ethAddress, discordId}
  } catch (err) {
    console.log('There was an error profile data', err)
    return {}
  }
}

// Function to fetch certificates when the page loads
const getAccomplishmentData = async (certHash) => {
  const requestData = {
    headers: {
      'Content-Type': 'application/json'
    },
    queryStringParameters: {
      certHash: certHash
    }
  }

  try {
    const dataObject = await API.get(
      'accomplishmentsapi',
      '/certs/accomplishment_data',
      requestData
    )
    if (!dataObject.Count) {
      console.log('No data for that certHash')
      return {}
    }
    // At most 1 result should come back
    const data = dataObject.Items[0]
    console.log('Accomplishment data:', data)
    // Que pasa si no estan definidos
    const name = data.name || null
    const certName = data.certName || null
    const date = data.date || null

    return {certName, date, name}
  } catch (err) {
    console.log('There was an error fetching accomplishment data', err)
    return {}
  }
}

const passNotTakenCert = async (certName) => {
  // Upload DynamoDB entries
  let user = ''
  try {
    user = await Auth.currentAuthenticatedUser()
  } catch (err) {
    console.log('Error fetching user', err)
  }

  const token = user.signInUserSession.idToken.jwtToken
  const requestData = {
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    method: 'PUT',
    body: {
      certName
    }
  }

  try {
    await API.put('userapi', '/users/pass_not_taken', requestData)
    console.log('Successfully updated dynamo table')

    const fullName = user.signInUserSession.idToken.payload.name
    const email = user.signInUserSession.idToken.payload.email

    const genCertReqData = {
      headers: {
        Authorization: token,
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: {
        certName,
        fullName,
        email,
        date: getDate(Date.now())
      }
    }
    // No need to wait for this to finish.
    // It'll run in the background and update the cert link automatically
    console.log(
      'Generating cert for pass not taken cert. genReqParam:',
      genCertReqData
    )
    API.post('gencertapi', '/generate', genCertReqData)
  } catch (err) {
    console.log('There was an error', err)
    return {}
  }
}

// Promise - decouple
const failNotTakenCert = async (certName) => {
  // Upload DynamoDB entries
  let user = ''
  try {
    user = await Auth.currentAuthenticatedUser()
  } catch (err) {
    console.log('Error fetching user', err)
  }

  const token = user.signInUserSession.idToken.jwtToken
  const requestData = {
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    method: 'PUT',
    body: {
      certName
    }
  }

  try {
    API.put('userapi', '/users/fail_not_taken/', requestData)
  } catch (err) {
    console.log('There was an error', err)
    return {}
  }
}

// Return different codes to display the appropriate page
const canTakeExam = (passed, failed, notTaken, certName) => {
  if (!isValidCertName(certName)) {
    return 'InvalidCertName'
  }
  // If passed, it cannot be retaken
  for (const p of passed) {
    if (p.name.toLowerCase() === certName.toLowerCase()) {
      return 'AlreadyPassed'
    }
  }
  // If failed,
  const currDate = Date.now()
  for (const f of failed) {
    if (f.name.toLowerCase() === certName.toLowerCase()) {
      // It can only be retaken if the date is valid
      return f.retakenDate <= currDate ? 'CanRetake' : 'WaitFailed'
    }
  }
  // Else, it can only be taken if it's in not_taken
  for (const n of notTaken) {
    if (n.name.toLowerCase() === certName.toLowerCase()) {
      return 'NotTaken'
    }
  }
  return 'HasNotPurchased'
}

const passFailedCert = async (certName) => {
  // Upload DynamoDB entries
  let user = ''
  try {
    user = await Auth.currentAuthenticatedUser()
  } catch (err) {
    console.log('Error fetching user', err)
  }

  const token = user.signInUserSession.idToken.jwtToken
  const requestData = {
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    method: 'PUT',
    body: {
      certName
    }
  }

  try {
    await API.put('userapi', '/users/pass_failed_cert', requestData)

    const fullName = user.signInUserSession.idToken.payload.name
    const email = user.signInUserSession.idToken.payload.email

    const genCertReqData = {
      headers: {
        Authorization: token,
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: {
        certName,
        fullName,
        email,
        date: getDate(Date.now())
      }
    }
    // No need to wait for this to finish.
    // It'll run in the background and update the cert link automatically
    console.log(
      'Generating cert for pass failed cert. genReqParam:',
      genCertReqData
    )
    API.post('gencertapi', '/generate', genCertReqData)
  } catch (err) {
    console.log('There was an error', err)
    return {}
  }
}

const failFailedCert = async (certName) => {
  // Upload DynamoDB entries
  let user = ''
  try {
    user = await Auth.currentAuthenticatedUser()
  } catch (err) {
    console.log('Error fetching user', err)
  }

  const token = user.signInUserSession.idToken.jwtToken
  const requestData = {
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    method: 'PUT',
    body: {
      certName
    }
  }

  try {
    await API.put('userapi', '/users/fail_failed', requestData)
  } catch (err) {
    console.log('There was an error', err)
    return {}
  }
}

// Display it if the discord ID is not in the system
// Regardless of whether the user has bought or not
const getDiscordId = async (currentUser) => {
  const token = currentUser.signInUserSession.idToken.jwtToken
  try {
    const requestData = {
      headers: {
        Authorization: token,
        'Content-Type': 'application/json'
      }
    }

    const userData = await API.get('userapi', '/users', requestData)
    const {discordId} = userData
    return discordId
  } catch (err) {
    console.log(err)
    // To be safe
    return undefined
  }
}

const hasPurchasedCerts = (userData) => {
  const {passed, failed, not_taken} = userData

  // They can add it only if they've purchased something
  if (passed && passed.length > 0) return true
  if (failed && failed.length > 0) return true
  if (not_taken && not_taken.length > 0) return true

  // They haven't purchased anything. They cannot join discord yet
  return false
}

/*
 * 1. Get user
 * 2. See if it's in the server
 * 3. If not, stop and communicate
 * 4. If he's in the server, process as normal
 */

const updateDiscordIdAndAddRoleToServer = async (cognitouser, newMemberId) => {
  const token = cognitouser.signInUserSession.idToken.jwtToken
  try {
    console.log('Getting user data')
    const requestData = {
      headers: {
        Authorization: token,
        'Content-Type': 'application/json'
      },
      method: 'GET'
    }

    const userData = await API.get('userapi', '/users', requestData)

    const hasPurchased = hasPurchasedCerts(userData)
    if (!hasPurchased) {
      console.log(
        'You need to purchase some certificates before being able to join the community'
      )
      return 'HasNotPurchased'
    }

    const updateIdData = {
      headers: {
        Authorization: token,
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: {
        newMemberId
      }
    }
    console.log(`Trying to add role to memberId ${newMemberId}`)
    const resMessage = await API.post('discordapi', '/profile', updateIdData)
    console.log('Res message:', resMessage)
    if (!resMessage) {
      return 'Error'
    } else if (resMessage.includes('Member not found')) {
      // The user has not joined the server or the ID is not correct
      return 'NotFound'
    } else if (resMessage.includes('Error')) {
      // Has Purchased, there was an error
      return 'Error'
    } else if (resMessage.includes('Success')) {
      // Has Purchased, joined the server and the ID is correct
      console.log('Storing discord id in db')
      const requestData = {
        headers: {
          Authorization: token,
          'Content-Type': 'application/json'
        },
        method: 'PUT',
        body: {
          newMemberId
        }
      }
      await API.put('userapi', '/users/update_discord_id', requestData)
      return 'Success'
    }

    return 'Error'
  } catch (err) {
    console.log('There was an error updating discordId', err)
    // Use enums
    return 'Error'
  }
}

const updateEthAddress = async (cognitouser, ethAddress) => {
  const token = cognitouser.signInUserSession.idToken.jwtToken
  const requestData = {
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    method: 'PUT',
    body: {
      ethAddress
    }
  }
  console.log('Updating eth address')
  try {
    await API.put('userapi', '/users/update_eth_address', requestData)
    console.log('Successfully updated ethAddress')
    return true
  } catch (err) {
    console.log('There was an error updating eth address', err)
    return false
  }
}

// Not in use anymore
const updateEthAddressAndDiscordId = async (
  cognitouser,
  ethAddress,
  newMemberId
) => {
  const token = cognitouser.signInUserSession.idToken.jwtToken
  try {
    console.log('DynamoDB: Updating eth address and discordId')

    const requestData = {
      headers: {
        Authorization: token,
        'Content-Type': 'application/json'
      },
      method: 'PUT',
      body: {
        ethAddress,
        newMemberId
      }
    }
    await API.put(
      'userapi',
      '/users/update_eth_address_and_discord_id',
      requestData
    )

    console.log('Successfully updated data in DynamoDB')

    const userData = await API.get('userapi', '/users', requestData)

    if (!hasPurchasedCerts(userData)) {
      console.log(
        'You need to purchase some certificates before being able to join the community'
      )
      return false
    }

    const updateIdData = {
      headers: {
        Authorization: token,
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: {
        newMemberId
      }
    }
    console.log('Updating roles in discord server')
    // No need to wait here
    await API.post('discordapi', '/profile', updateIdData)
    console.log('Success')
    return true
  } catch (err) {
    console.log(
      'There was an error updating your discord id or giving you access to the server',
      err
    )
    return false
  }
}

export {
  getCertificates,
  getProfileData,
  canTakeExam,
  passFailedCert,
  passNotTakenCert,
  failNotTakenCert,
  failFailedCert,
  getAccomplishmentData,
  updateEthAddress,
  updateDiscordIdAndAddRoleToServer as updateDiscordId,
  updateEthAddressAndDiscordId,
  getDiscordId
}
