const failedReducer = (state = [], action) => {
  switch (action.type) {
    case 'clean':
      return []
    case 'addFailed':
      return [...state, action.payload]
    case 'initFailed':
      return [...action.payload]
    case 'deleteFailed':
      return state.filter((f) => f.name !== action.payload.name)
    case 'updateFailed':
      return state.map((f) => {
        if (f.name.toLowerCase() === action.payload.name.toLowerCase()) {
          return {
            ...f,
            retakenDate: action.payload.retakenDate
          }
        } else {
          return f
        }
      })
    default:
      return state
  }
}
export default failedReducer
