import React from 'react'
import {Button, Form, Grid, Header, Segment} from 'semantic-ui-react'
import {useState, useContext, useEffect, useMemo} from 'react'
import {Link} from 'react-router-dom'

import {useDispatch} from 'react-redux'
import {bindActionCreators} from 'redux'
import {actionCreators} from '../state/index'
import {getCertificates} from '../helpers/dynamoDBHelper'

import {Auth} from 'aws-amplify'
import {UserContext} from '../context/UserContext'
import ErrorMessage from '../components/messages/ErrorMessage'
import {isAuth} from '../helpers/auth'

// Test values. Come from google doc. Change for real API values from env variable
import ReCAPTCHA from 'react-google-recaptcha'
import {Navigate} from 'react-router-dom'
const SiteKey = process.env.REACT_APP_CAPTCHA_KEY

const SignInPage = () => {
  const [email, setEmail] = useState(null)
  const [password, setPassword] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const [passedCaptcha, setPassedCaptcha] = useState(false)
  const {currentUser, setCurrentUser} = useContext(UserContext)
  const dispatch = useDispatch()
  const AC = bindActionCreators(actionCreators, dispatch)
  let isMounted = false

  useEffect(() => {
    isMounted = true
    return () => (isMounted = false)
  })

  const isAlreadyAuth = useMemo(() => isAuth(currentUser), [currentUser])
  if (isAlreadyAuth) {
    console.log('User is already logged in. Redirecting home.')
    return <Navigate to="/" />
  }

  const fetchAvailableCerts = async (user) => {
    if (!user) return
    try {
      const {passed, failed, notTaken} = await getCertificates(user)

      AC.initializeCerts({passed, failed, notTaken})
      AC.stopLoading()
      console.log('All certificates have been fetched after signing in')
    } catch (err) {
      console.log('Error fetching certs', err)
    }
  }

  const signIn = async () => {
    if (!email || !password) return
    console.log('Trying to log in')
    try {
      const cognitoUser = await Auth.signIn(email.trim(), password)
      await fetchAvailableCerts(cognitoUser)
      if (isMounted) setCurrentUser(cognitoUser)
    } catch (err) {
      console.log('Error signing in', err)
      if (err.name.includes('UserNotFoundException')) {
        setErrorMessage('User does not exist')
      } else if (err.name.includes('NotAuthorizedException')) {
        setErrorMessage('Incorrect username or password.')
      } else if (err.name.includes('UserNotConfirmedException')) {
        setErrorMessage('This account has not been confirmed')
      } else {
        setErrorMessage('There was an error siging in.')
      }
    }
  }

  const onCaptcha = () => {
    setPassedCaptcha(true)
  }

  const onChangeEmail = (e) => {
    e.persist()
    setEmail(e.target.value)
    setErrorMessage(null)
  }

  const onChangePassword = (e) => {
    e.persist()
    setPassword(e.target.value)
    setErrorMessage(null)
  }

  return (
    <div className="login-form">
      <style>{`
      body > div,
      body > div > div,
      body > div > div > div.login-form {
        height: 100%;
      }
    `}</style>
      <Grid textAlign="center" style={{height: '75%'}} verticalAlign="middle">
        <Grid.Column style={{maxWidth: 450}}>
          <Header as="h2" color="yellow" textAlign="center">
            Log in to your account
          </Header>
          <Form size="large">
            <Segment stacked>
              <Form.Input
                onChange={onChangeEmail}
                fluid
                icon="user"
                iconPosition="left"
                placeholder="Email address"
                required
              />
              <Form.Input
                onChange={onChangePassword}
                fluid
                icon="lock"
                iconPosition="left"
                placeholder="Password"
                type="password"
                required
              />
              <Button
                onClick={signIn}
                color="yellow"
                fluid
                size="large"
                disabled={!passedCaptcha}
              >
                Login
              </Button>
              <div style={{marginTop: '1rem'}}>
                Forgot your password?{' '}
                <Link to="/forgotpassword">Click here</Link>
              </div>
            </Segment>
          </Form>
          {errorMessage ? <ErrorMessage errorMessage={errorMessage} /> : null}
          <Header as="h1" color="yellow" textAlign="center">
            <ReCAPTCHA sitekey={SiteKey} onChange={onCaptcha} />
          </Header>
        </Grid.Column>
      </Grid>
    </div>
  )
}

export default SignInPage
