/* eslint-disable comma-dangle */
/* eslint-disable require-jsdoc */
/* eslint-disable react/jsx-key */
/* eslint-disable no-undef */
import {createMedia} from '@artsy/fresnel'
import React from 'react'
import {Container, Icon, Menu, Sidebar} from 'semantic-ui-react'

const AppMedia = createMedia({
  breakpoints: {
    mobile: 320,
    tablet: 768,
    computer: 992,
    largeScreen: 1200,
    widescreen: 1920,
  },
})
const mediaStyles = AppMedia.createMediaStyle()
const {Media, MediaContextProvider} = AppMedia

// Hide bar when clicking on a Link
const NavBarMobile = (props) => {
  const {
    children,
    leftItems,
    onPusherClick,
    onToggle,
    rightItemsNoAuth,
    rightItemsAuth,
    visible,
    isAuthorized,
  } = props

  const items = leftItems.concat(
    isAuthorized ? rightItemsAuth : rightItemsNoAuth
  )

  return (
    <Sidebar.Pushable>
      <Sidebar
        as={Menu}
        animation="overlay"
        icon="labeled"
        inverted
        items={items}
        vertical
        visible={visible}
      />
      <Sidebar.Pusher
        dimmed={visible}
        onClick={onPusherClick}
        style={{minHeight: '100vh'}}
      >
        <Menu fixed="top" inverted>
          <Menu.Item>
            <h4>Teach Yourself Crypto</h4>
          </Menu.Item>
          <Menu.Item onClick={onToggle}>
            <Icon name="sidebar" />
          </Menu.Item>
        </Menu>
        {children}
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  )
}

const NavBarDesktop = (props) => {
  const {leftItems, rightItemsAuth, rightItemsNoAuth, isAuthorized} = props
  const rightItems = isAuthorized ? rightItemsAuth : rightItemsNoAuth

  return (
    <Menu fixed="top" inverted style={{color: 'white', fontSize: '1.5rem'}}>
      <Menu.Item>
        <h2>Teach Yourself Crypto</h2>
      </Menu.Item>

      {leftItems.map((item) => (
        <Menu.Item {...item} />
      ))}

      <Menu.Menu position="right">
        {rightItems.map((item) => (
          <Menu.Item {...item} />
        ))}
      </Menu.Menu>
    </Menu>
  )
}

const NavBarChildren = (props) => {
  return (
    <Container style={{marginTop: '4em', paddingTop: '6rem'}}>
      {props.children}
      <Container
        fluid
        text
        style={{
          marginBottom: '3rem',
          padding: '3rem'
        }}
      ></Container>
    </Container>
  )
}


class NavBar extends React.Component {
  state = {
    visible: false,
  }

  handlePusher = () => {
    const {visible} = this.state

    if (visible) this.setState({visible: false})
  }

  handleToggle = () => this.setState({visible: !this.state.visible})

  render() {
    const {
      children,
      leftItems,
      rightItemsNoAuth,
      rightItemsAuth,
      isAuthorized,
    } = this.props
    const {visible} = this.state

    return (
      <div>
        <style>{mediaStyles}</style>
        <MediaContextProvider>
          <Media at="mobile">
            <NavBarMobile
              leftItems={leftItems}
              onPusherClick={this.handlePusher}
              onToggle={this.handleToggle}
              visible={visible}
              rightItemsNoAuth={rightItemsNoAuth}
              rightItemsAuth={rightItemsAuth}
              isAuthorized={isAuthorized}
            >
              <NavBarChildren>{children}</NavBarChildren>
            </NavBarMobile>
          </Media>

          <Media greaterThan="mobile">
            <NavBarDesktop
              leftItems={leftItems}
              rightItemsNoAuth={rightItemsNoAuth}
              rightItemsAuth={rightItemsAuth}
              isAuthorized={isAuthorized}
            />
            <NavBarChildren>{children}</NavBarChildren>
          </Media>
        </MediaContextProvider>
      </div>
    )
  }
}

export default NavBar
