import CertificateTable from '../components/certificates/CertificateTable'
import {Navigate} from 'react-router-dom'
import NoCertsPage from './NoCertsPage'
import React from 'react'
import Loading from '../components/messages/Loading'

import {Container} from 'semantic-ui-react'
import {useSelector} from 'react-redux'
import {useContext} from 'react'
import {UserContext} from '../context/UserContext'
import {isAuth} from '../helpers/auth'

const CertificatePage = () => {
  const {currentUser} = useContext(UserContext)
  const passedCerts = useSelector((state) => state.passed)
  const loading = useSelector((state) => state.loading)

  if (!isAuth(currentUser)) {
    console.log('CertPage. User not signed in. Redirecting')
    return <Navigate to="/signin" />
  }

  return (
    <Container>
      {loading ? (
        <Loading
          header={'Just one second'}
          text={'We\'re fetching your certificates'}
        />
      ) : !passedCerts || passedCerts.length === 0 ? (
          <NoCertsPage/>
      ) : (
          <CertificateTable passedCerts={passedCerts} />
      )}
    </Container>
  )
}

export default CertificatePage
