import React from 'react'
import {Button, Form, Grid, Header, Message, Segment} from 'semantic-ui-react'

import {useMemo, useState, useEffect, useContext} from 'react'
import {Link} from 'react-router-dom'
import {Navigate} from 'react-router-dom'
import {isAuth} from '../../helpers/auth'

import {Auth} from 'aws-amplify'
import {UserContext} from '../../context/UserContext'
import ErrorMessage from '../messages/ErrorMessage'
import {isAddress} from 'ethereum-address'

const validPasswordPattern = new RegExp(
  '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\\^$*.\\[\\]{}\\(\\)?\\-"!@#%&\\/,><\':;|_~`])\\S{8,99}$'
)

const PasswordInformation = () => {
  return (
    <Message
      warning
      header="Your password must contain, at least:"
      list={[
        '8 characters',
        'One number',
        'One uppercase letter',
        'One lowercase letter',
        'One special character: ^$*.[]{}()?-"!@#%&/,><’:;|_~`'
      ]}
    />
  )
}

const PasswordDontMatch = () => {
  return <Message error header="Passwords don't match" />
}

const SignUp = ({
  setSignUpUsername,
  setSubmitted,
  setEthAddress,
  setPassword
}) => {
  const [email, setEmail] = useState(null)
  const [fullname, setFullname] = useState(null)
  const [password, setInternalPassword] = useState(null)
  const [confirmPass, setConfirmPass] = useState(null)
  const [passwordsMatch, setPasswordsMatch] = useState(false)
  const [validPassword, setValidPassword] = useState(false)

  const [errorMessage, setErrorMessage] = useState(null)

  const [ethAddressError, setEthAddressError] = useState(null)
  const [internalEthAddress, setInternalEthAddress] = useState(null)

  const {currentUser, setCurrentUser} = useContext(UserContext)

  let isMounted = false
  useEffect(() => {
    isMounted = true
    return () => (isMounted = false)
  })

  const isAlreadyAuth = useMemo(() => isAuth(currentUser), [currentUser])
  if (isAlreadyAuth) {
    console.log('User is already logged in and confirmed. Redirecting home.')
    return <Navigate to="/" />
  }

  useEffect(() => {
    const isValid = password === null || validPasswordPattern.test(password)
    if (isValid) {
      setValidPassword(true)
    } else {
      setValidPassword(false)
    }
    if (password === confirmPass) {
      setPasswordsMatch(true)
    } else {
      setPasswordsMatch(false)
    }
  })

  const handleSignUp = async () => {
    if (
      internalEthAddress &&
      internalEthAddress.length > 0 &&
      !isAddress(internalEthAddress)
    ) {
      setEthAddressError('Invalid Ethereum address')
      return
    }
    if (!passwordsMatch) {
      return
    }
    try {
      const signUpParams = {
        username: email.trim().toLowerCase(),
        password: password,
        attributes: {
          email: email.trim().toLowerCase(),
          name: fullname
        }
      }

      const cognitoUser = await Auth.signUp(signUpParams)
      if (!isMounted) return
      setCurrentUser(cognitoUser)
      setPassword(password)
      setSignUpUsername(email.trim().toLowerCase())
      setSubmitted(true)
    } catch (e) {
      console.log('Error signing up', e)
      if (e.name.includes('UsernameExistsException')) {
        setErrorMessage('That email is already registered')
      }
    }
  }

  const onChangeEmail = (e) => {
    e.persist()
    setEmail(e.target.value)
    setErrorMessage('')
  }

  const onChangeName = (e) => {
    e.persist()
    setFullname(e.target.value)
  }
  const onChangePassword = (e) => {
    e.persist()
    setInternalPassword(e.target.value)
  }

  const onChangeConfirmPass = (e) => {
    e.persist()
    setConfirmPass(e.target.value)
  }
  const onChangeEthAddress = (e) => {
    e.persist()
    setEthAddress(e.target.value)
    setInternalEthAddress(e.target.value)
    setEthAddressError(null)
  }

  return (
    <div className="login-form">
      <style>{`
      body > div,
      body > div > div,
      body > div > div > div.login-form {
        height: 100%;
      }
    `}</style>
      <Grid textAlign="center" style={{height: '75%'}} verticalAlign="middle">
        <Grid.Column style={{maxWidth: 450}}>
          <Header as="h2" color="yellow" textAlign="center">
            Create your account
          </Header>
          <Form size="large" onSubmit={handleSignUp}>
            <Segment stacked>
              <Form.Field required>
                <Form.Input
                  fluid
                  required
                  icon="user"
                  iconPosition="left"
                  placeholder="Name (as it will appear in the certificates)"
                  onChange={onChangeName}
                />
              </Form.Field>
              <Form.Input
                required
                fluid
                type="email"
                icon="mail"
                iconPosition="left"
                placeholder="E-mail address"
                onChange={onChangeEmail}
              />
              <Form.Input
                fluid
                icon="lock"
                iconPosition="left"
                placeholder="Password"
                required
                type="password"
                minLength="8"
                pattern={
                  '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\\^$*.\\[\\]{}\\(\\)?\\-"!@#%&\\/,><\':;|_~`])\\S{8,99}$'
                }
                onChange={onChangePassword}
              />
              <Form.Input
                fluid
                icon="lock"
                iconPosition="left"
                placeholder="Confirm password"
                type="password"
                required
                onChange={onChangeConfirmPass}
              />
              <Form.Input
                fluid
                icon="key"
                iconPosition="left"
                placeholder="Ethereum address (optional)"
                onChange={onChangeEthAddress}
              />
              <Button color="yellow" fluid size="large">
                Sign up
              </Button>
              <div style={{marginTop: '1rem'}}>
                Already have an account? <Link to="/signin">Sign In</Link>
              </div>
            </Segment>
          </Form>
          {validPassword ? null : <PasswordInformation />}
          {passwordsMatch ? null : <PasswordDontMatch />}
          {ethAddressError ? (
            <ErrorMessage errorMessage={ethAddressError} />
          ) : null}
          {errorMessage ? <ErrorMessage errorMessage={errorMessage} /> : null}
        </Grid.Column>
      </Grid>
    </div>
  )
}

export default SignUp
