import React from 'react'
import {Button, Form, Grid, Header, Message, Segment} from 'semantic-ui-react'

import {useState, useContext} from 'react'
import {useNavigate} from 'react-router-dom'

import {Auth} from 'aws-amplify'
import {UserContext} from '../../context/UserContext'
import {updateEthAddress} from '../../helpers/dynamoDBHelper.js'

import {useDispatch} from 'react-redux'
import {bindActionCreators} from 'redux'
import {actionCreators} from '../../state/index'
import {getCertificates} from '../../helpers/dynamoDBHelper'

const ConfirmSignUpError = ({errorMessage}) => {
  return <Message error header={errorMessage} />
}

const ConfirmSignUp = ({email, ethAddress, password}) => {
  const navigate = useNavigate()
  const [confirmationCode, setConfirmationCode] = useState(false)
  const {currentUser, setCurrentUser} = useContext(UserContext)
  const [errorMessage, setErrorMessage] = useState(null)
  const dispatch = useDispatch()
  const AC = bindActionCreators(actionCreators, dispatch)

  const fetchAvailableCerts = async (user) => {
    console.log('Fetching certs for current user:', user)
    if (!user) return
    try {
      const {passed, failed, notTaken} = await getCertificates(user)

      AC.initializeCerts({passed, failed, notTaken})
      AC.stopLoading()
      console.log('All certificates have been fetched')
    } catch (err) {
      console.log('Error fetching certs', err)
    }
  }

  const handleConfirm = async () => {
    if (!confirmationCode) {
      return
    }
    try {
      const username = email.toLowerCase().trim()

      await Auth.confirmSignUp(username, confirmationCode)
      const user = await Auth.signIn({
        username: email,
        password: password
      })

      fetchAvailableCerts(user)

      if (ethAddress) {
        updateEthAddress(user, ethAddress)
      }
      setCurrentUser(user)

      console.log('Successfull sign up')
      navigate('/')
    } catch (err) {
      console.log('error confirming sign up', err)
      if (err.name.includes('CodeMismatchException')) {
        setErrorMessage(
          'The confirmation code is not correct. Please try again'
        )
      }
      if (err.name.includes('LimitExceededException')) {
        setErrorMessage('Attempt limit exceeded, please try after some time.')
      }
      if (err.name.includes('NotAuthorizedException')) {
        setErrorMessage('This user is already confirmed')
        navigate('/signin')
      }
    }
  }

  const resendConfirmationCode = async () => {
    try {
      const username = cognitoUser.user.username
      await Auth.resendSignUp(username)
      console.log('code resent successfully')
    } catch (err) {
      console.log('error resending code: ', err)
    }
  }

  if (currentUser && currentUser.userConfirmed) {
    console.log('User is already logged in. Redirecting home.')
    navigate('/')
  }

  const onConfirmationCode = (e) => {
    e.persist()
    setConfirmationCode(e.target.value)
  }

  return (
    <div className="login-form">
      <style>{`
      body > div,
      body > div > div,
      body > div > div > div.login-form {
        height: 100%;
      }
    `}</style>
      <Grid textAlign="center" style={{height: '75%'}} verticalAlign="middle">
        <Grid.Column style={{maxWidth: 450}}>
          <Header as="h2" color="yellow" textAlign="center">
            Confirm your registration
          </Header>
          <Form size="large" onSubmit={handleConfirm}>
            <Segment stacked>
              <Form.Field required>
                <Form.Input
                  fluid
                  required
                  icon="user"
                  iconPosition="left"
                  placeholder="Enter confirmation code"
                  onChange={onConfirmationCode}
                />
              </Form.Field>
              <Button color="yellow" fluid size="large">
                Confirm
              </Button>
            </Segment>
          </Form>
          <br />
          <Button primary fluid size="large" onClick={resendConfirmationCode}>
            Resend code
          </Button>
          {errorMessage ? (
            <ConfirmSignUpError errorMessage={errorMessage} />
          ) : null}
        </Grid.Column>
      </Grid>
    </div>
  )
}
export default ConfirmSignUp
