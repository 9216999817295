import React from 'react'
import {Container, Header, Image} from 'semantic-ui-react'

const logo = require('../assets/images/logo.png')

const Contact = () => {
  return (
    <div>
      <Container text>
        <Image
          centered
          alt="Teach Yourself Crypto logo"
          src={logo}
          size="huge"
          style={{margin: '2rem'}}
        />
        <Header
          as="h3"
          content="Shoot us an email at support@teachyourselfcrypto.com"
          style={{
            marginTop: '2em'
          }}
          textAlign="center"
        />
      </Container>
    </div>
  )
}

export default Contact
