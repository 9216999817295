/* eslint-disable operator-linebreak */
import {React, useEffect} from 'react'
import swal from 'sweetalert'
import {useNavigate} from 'react-router-dom'
import {useContext, useState, useMemo} from 'react'
import {UserContext} from '../context/UserContext'
import {Button, Grid, Card, Image, Header, Form} from 'semantic-ui-react'
import {isAuth} from '../helpers/auth'
import {updateEthAddress, updateDiscordId} from '../helpers/dynamoDBHelper'
import ErrorMessage from '../components/messages/ErrorMessage'
import SuccessMessage from '../components/messages/SuccessMessage'
import Loading from '../components/messages/Loading'
import {isAddress} from 'ethereum-address'
import {getProfileData} from '../helpers/dynamoDBHelper'
import WarningDiscord from '../components/messages/WarningDiscord'

const discordServerURL = process.env.REACT_APP_DISCORD_SERVER_URL

const ProfilePage = () => {
  const navigate = useNavigate()
  const [newEthAddress, setNewEthAddress] = useState(null)
  const [newDiscordId, setNewDiscordId] = useState(null)
  const [ethereumAddressMessage, setEthAddressMessage] = useState(null)
  const [discordUpdateMessage, setDiscordUpdateMessage] = useState(null)
  const {currentUser} = useContext(UserContext)
  const [updateDiscord, setUpdateDiscord] = useState(null)
  const [processingMessage, setProcessingMessage] = useState(null)
  const [oldEthAddress, setOldEthAddress] = useState('')
  const [oldDiscordId, setOldDiscordId] = useState(null)

  useEffect(async () => {
    if (updateDiscord !== null) return
    try {
      const {ethAddress, discordId} = await getProfileData(currentUser)
      setOldDiscordId(discordId)
      setUpdateDiscord(discordId === undefined)
      setOldEthAddress(ethAddress)
    } catch (err) {
      console.log('There was an error profile data', err)
    }
  })

  const isAlreadyAuth = useMemo(() => isAuth(currentUser), [currentUser])
  if (!isAlreadyAuth) {
    console.log('User is not logged in and confirmed. Redirecting home.')
    navigate('/')
  }

  const handleUpdateEthAddress = async () => {
    if (!newEthAddress || newEthAddress.length === 0) {
      setEthAddressMessage('Your new Ethereum address cannot be empty')
      return
    } else if (!isAddress(newEthAddress)) {
      setEthAddressMessage('Your new Ethereum address is not valid')
      return
    }

    try {
      const success = await updateEthAddress(currentUser, newEthAddress)
      if (success) {
        setEthAddressMessage(
          'Your Ethereum address has been successfully updated'
        )
        const newAdd = newEthAddress
        setNewEthAddress(null)
        setOldEthAddress(newAdd)
      } else {
        setEthAddressMessage(
          'There was an error updating your Ethereum address. Try again later.'
        )
      }
    } catch (e) {
      console.log('Error updating ethAddress', e)
      setEthAddressMessage(
        'There was an error updating your Ethereum address. Try again later.'
      )
    }
  }

  const handleUpdateDiscordId = async () => {
    if (!newDiscordId || newDiscordId.length === 0) {
      setDiscordUpdateMessage('Your new Discord Id cannot be empty')
      return
    }
    try {
      setProcessingMessage('One second, we are processing your request')
      const response = await updateDiscordId(currentUser, newDiscordId.trim())
      switch (response) {
        case 'Success':
          swal(
            'Congratulations',
            'You have now access to our private community',
            'success'
          )
          setUpdateDiscord(false)
          setDiscordUpdateMessage(null)
          setOldDiscordId(newDiscordId.trim())
          break
        case 'HasNotPurchased':
          swal(
            'You cannot access our private community yet',
            'To access the community you need to purchase exams',
            'warning'
          )
          setDiscordUpdateMessage(null)
          break
        case 'NotFound':
          swal(
            'We could not found that ID in the server',
            'Use the link above to join and then click on Update again. If you have already joined, make sure the ID you are using is the same one you used to join the server.',
            'warning'
          )
          setDiscordUpdateMessage(null)
          break
        case 'Error':
          setDiscordUpdateMessage(
            'There was an error updating your Discord Id. Try again later.'
          )
          break
      }
    } catch (e) {
      console.log('Error updating discordId', e)
      setDiscordUpdateMessage(
        'There was an error updating your Discord ID. Try again later.'
      )
    } finally {
      setProcessingMessage(null)
    }
  }

  const onChangeDiscordId = (e) => {
    e.persist()
    setNewDiscordId(e.target.value)
    setDiscordUpdateMessage(null)
  }

  const onChangeEthAddress = (e) => {
    e.persist()
    setNewEthAddress(e.target.value)
    setEthAddressMessage(null)
  }

  if (updateDiscord === null) {
    return (
      <Loading header={'Just one second'} text={"We're loading your data"} />
    )
  }

  return (
    <Grid
      container
      columns={2}
      divided
      relaxed
      stackable
      style={{marginTop: '5rem'}}
    >
      <Grid.Column>
        <Card>
          <Image
            src={require('../assets/images/user.png')}
            wrapped
            ui={false}
          />
          <Card.Content style={{marginBottom: '1rem'}}>
            <Card.Header>Name: {currentUser.attributes.name}</Card.Header>
            <Card.Description>
              <span className="email">
                Email: {currentUser.attributes.email}
              </span>
            </Card.Description>
            <Card.Description>
              {oldDiscordId ? `Discord ID: ${oldDiscordId}.` : null}
            </Card.Description>
          </Card.Content>
        </Card>
      </Grid.Column>
      <Grid.Column verticalAlign="middle">
        <Grid.Row>
          <Grid.Column>
            <Grid textAlign="center" style={{height: '75%'}}>
              <Grid.Column style={{maxWidth: 450}}>
                <Header as="h2" textAlign="center">
                  Update Ethereum Address
                </Header>
                <Form size="large" onSubmit={handleUpdateEthAddress}>
                  <Form.Input
                    fluid
                    icon="key"
                    iconPosition="left"
                    placeholder={
                      oldEthAddress ? oldEthAddress : 'Ethereum address'
                    }
                    onChange={onChangeEthAddress}
                  />
                  <Button fluid size="large">
                    Update
                  </Button>
                </Form>
                {ethereumAddressMessage ? (
                  ethereumAddressMessage.includes('error') ||
                  ethereumAddressMessage.includes('not valid') ||
                  ethereumAddressMessage.includes('empty') ? (
                    <ErrorMessage errorMessage={ethereumAddressMessage} />
                  ) : (
                    <SuccessMessage message={ethereumAddressMessage} />
                  )
                ) : null}
              </Grid.Column>
            </Grid>
          </Grid.Column>

          <Grid.Column style={{marginTop: '4.5rem'}}>
            <Grid textAlign="center" style={{height: '75%'}}>
              <Grid.Column style={{maxWidth: 450}}>
                {updateDiscord ? (
                  <div>
                    <Header as="h2" textAlign="center">
                      Update Discord ID
                    </Header>
                    <Header as="h4" textAlign="center">
                      Remember: You need to{' '}
                      <a href={discordServerURL}>join the Discord server</a>{' '}
                      first.
                    </Header>
                    <Form size="large" onSubmit={handleUpdateDiscordId}>
                      <Form.Input
                        fluid
                        icon="chat"
                        iconPosition="left"
                        placeholder={'New Discord ID'}
                        onChange={onChangeDiscordId}
                      />
                      <Button fluid size="large">
                        Update
                      </Button>
                    </Form>
                    {newDiscordId ? <WarningDiscord /> : null}
                    {discordUpdateMessage ? (
                      discordUpdateMessage.includes('error') ||
                      discordUpdateMessage.includes('empty') ? (
                        <ErrorMessage errorMessage={discordUpdateMessage} />
                      ) : (
                        <SuccessMessage message={discordUpdateMessage} />
                      )
                    ) : null}
                    {processingMessage ? (
                      <Loading
                        header={'Just one second'}
                        text={processingMessage}
                      />
                    ) : null}
                  </div>
                ) : null}
              </Grid.Column>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid.Column>
    </Grid>
  )
}

export default ProfilePage
